import React, { useState, useEffect } from 'react'
import { CSSTransitionGroup } from 'react-transition-group'

import usePagination from '../../hooks/usePagination'
import useIntersect from '../../hooks/useIntersect'
import useMedia from '../../hooks/useMedia'
import { PaginationButton } from 'components/Collection/PaginationButton'
import { StrainCard } from 'components/StrainCard'
import './StrainCollection.scss'
import '../StrainCard/StrainCard.scss'

const StrainCollection = ({ strains }) => {
  const [pageSize, setPageSize] = useState(12)
  const [headerIntersectRef, headerEntry] = useIntersect({})
  const [transitionDirection, setTransitionDirection] = useState('')
  const strainsPaginated = usePagination([...strains], pageSize)
  const totalColumns = strains.length

  const columnCount = useMedia(
    // Media queries
    [
      '(min-width: 1600px)',
      '(min-width: 1212px)',
      '(min-width: 580px)',
      '(min-width: 340px)',
      '(min-width: 1px)',
    ],
    // Column counts (relates to above media queries by array index)
    [totalColumns, totalColumns, 3, 1, 1],
    // Default column count
    totalColumns
  )

  const handlePrevious = (e) => {
    e.preventDefault()
    setTransitionDirection('right')
    strainsPaginated.prev()
  }

  const handleNext = (e) => {
    e.preventDefault()
    setTransitionDirection('left')
    strainsPaginated.next()
  }

  // resetting to first page on renders prevents loading empty pages if the window size increases
  useEffect(() => {
    setPageSize(columnCount * 1)
  }, [columnCount])

  return (
    <div className='iliad__strain-collection--section'>
      <div className='iliad__strain-collection--header'>
        <div ref={headerIntersectRef}>
          <div>
            <h2>
              Strains <span>({strains.length - 1})</span>
            </h2>
            <div className='iliad__strain-collection--controls'>
              <PaginationButton
                variant='prev'
                disabled={strainsPaginated.currentPage <= 1}
                onClick={handlePrevious}
                aria-label='Click to Navigate to the previous page'
              />
              {/* used non-breaking space characters to save development time */}
              <span id='page-indicators'>
                <span className='iliad__display--no-mobile'>Page&nbsp;</span>
                <span>{strainsPaginated.currentPage} /&nbsp;</span>
                {strainsPaginated.totalPages}
              </span>

              <PaginationButton
                disabled={
                  strainsPaginated.currentPage >= strainsPaginated.totalPages
                }
                onClick={handleNext}
                aria-label='Click to navigate to the next page'
              />
            </div>
          </div>
        </div>
        <div
          className={`iliad__strain-collection--wrapper ${
            headerEntry.isIntersecting
              ? 'animation-strain-collection'
              : 'animation-strain-collection-fade-out'
          }`}>
          <div className='iliad__strain-collection--container'>
            <CSSTransitionGroup
              transitionName={`strains-${transitionDirection}`}
              transitionEnterTimeout={600}
              transitionLeaveTimeout={600}>
              <div
                key={strainsPaginated.currentPage}
                className='iliad__strain-collection--page'>
                <FlexBox>
                  {strainsPaginated.currentData().map((strain, index) => {
                    return (
                      <StrainCard
                        key={strain.name}
                        index={index}
                        {...strain}
                        currentPage={strainsPaginated.currentPage}
                      />
                    )
                  })}
                </FlexBox>
              </div>
            </CSSTransitionGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StrainCollection

const flexStyles = {
  display: 'flex',
  flexDirection: 'row',
  columnGap: '1rem',
}
const FlexBox = ({ children, ...props }) => (
  <div style={flexStyles} {...props}>
    {children}
  </div>
)
